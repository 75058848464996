console.group("inicializando o pack de imagens do app")



import '/var/www/apps/sfg/app/frontend/images/brand/app_logo_250.png';

import '/var/www/apps/sfg/app/frontend/images/brand/app_logo_400.png';

import '/var/www/apps/sfg/app/frontend/images/brand/app_logo_full.png';

import '/var/www/apps/sfg/app/frontend/images/brand/app_logo_full_original.png';

import '/var/www/apps/sfg/app/frontend/images/brand/app_symbol.png';

import '/var/www/apps/sfg/app/frontend/images/brand/app_symbol_250.png';

import '/var/www/apps/sfg/app/frontend/images/brand/app_symbol_400.png';

import '/var/www/apps/sfg/app/frontend/images/brand/app_text.png';

import '/var/www/apps/sfg/app/frontend/images/brand/app_text_150.png';

import '/var/www/apps/sfg/app/frontend/images/brand/app_text_250.png';

import '/var/www/apps/sfg/app/frontend/images/brand/app_text_400.png';

import '/var/www/apps/sfg/app/frontend/images/empty_image_large.png';

import '/var/www/apps/sfg/app/frontend/images/pub/background.jpg';

import '/var/www/apps/sfg/app/frontend/images/pub/header_bkg.jpg';

import '/var/www/apps/sfg/app/frontend/images/site/152-01.svg';


console.groupEnd();
