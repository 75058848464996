class M {
    static SUCCESS = "success";
    static ERROR = "error";
    static HELP = "help";
    static WARNING = "warning";
    static NEGATIVE_RED = "negative_red";
    static NEGATIVE_ACCENT = "negative_accent";
    static NEGATIVE_ACCENT_INVERSE = "negative_inverse";
    static NEGATIVE_ACCENT_AUX = "negative_aux";

    constructor() {
        this.cached = null;
        this.count = 1;
    };

    static pushRaw(params) {
        M.pop();

        this.cached = $.toast(params);

        return  this.cached;
    }

    static push(message, style, heading, confirmable_callback) {
        let params = {};
        params.textColor = "#444";
        params.hideAfter = 3000;
        params.heading = heading;
        params.message = message;

        if (checkIfEmpty(style))
            style = M.SUCCESS;

        if (style === M.ERROR) {
            params.bgColor = "#fff";
            params.textColor = "#444";

            if (checkIfEmpty(params.heading))
                params.heading = "<b class='accent-red-color-text'>Houve um problema</b>";
        } else if (style === M.SUCCESS) {
            params.bgColor = "#fdfdfd";

            if (checkIfEmpty(params.heading))
                params.heading = "<b class='accent-color-text'>Concluído</b>";
        } else if (style === M.WARNING) {
            params.bgColor = "#fdfdfd";

            if (checkIfEmpty(params.heading))
                params.heading = "<b class='accent-red-color-text'>Confirmar</b>";
        } else if (style === M.NEGATIVE_RED) {
            params.bgColor = "#F8333C";
            params.textColor = "#fff";
            params.hideAfter = 8000;
        } else if (style === M.NEGATIVE_ACCENT) {
            params.bgColor = "#ffc107";
            params.textColor = "#fff";
            params.hideAfter = 8000;
        } else if (style === M.NEGATIVE_ACCENT_AUX) {
            params.bgColor = "#607d8b";
            params.textColor = "#fff";
            params.hideAfter = 8000;
        } else if (style === M.NEGATIVE_ACCENT_INVERSE) {
            params.bgColor = "#373435";
            params.textColor = "#fff";
            params.hideAfter = 8000;
        } else {
            params.bgColor = "#fdfdfd";
            params.hideAfter = 8000;

            if (checkIfEmpty(params.heading))
                params.heading = "<b>Informação</b>";
        }

        let currentCount = M.count + 1;

        if (!!confirmable_callback) {
            let yes = "<br/><br/><b class='{count}_message_yes_button'>SIM</b>".format({
                count: currentCount
            });

            params.message = params.message + yes;
        }

        M.cached = $.toast({
            heading: params.heading,
            text: params.message,
            loader: false,
            bgColor: params.bgColor,
            textColor: params.textColor,
            hideAfter: params.hideAfter,
            allowToastClose: style == M.HELP || !!confirmable_callback,
            stack: !!confirmable_callback ? 1 : 3,
            position: 'bottom-right',
            beforeShow: function () {
                if (!!confirmable_callback) {
                    var yes = $(".{count}_message_yes_button".format({count: currentCount}));
                    yes.css("cursor", "pointer");

                    yes.click(function () {
                        confirmable_callback();

                        M.pop();
                    });
                }
            }
        });

        M.count +=  1;

        if (checkIfEmpty(params))
            return;

        return  M.cached;
    }

    static pop() {
        if (!! M.cached)
            M.cached.reset();
    }
}

export default M