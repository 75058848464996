console.group("inicializando o pack css do app")

/* !! #6151: sn - deixei comentado os assets do painel para isolar o build do site durante a portabilidade */


import './pub/recyclable.scss'
import './pub/application.scss'
import './pub/components/base'
import './pub/components/console'
import './pub/components/admin_messages'
import './pub/components/help_items'
import './pub/components/contracts'
import './pub/components/dash'
import './pub/components/console_contracts'
import './pub/components/start'
import './pub/components/users'
import './pub/components/app_themes'
import './pub/components/changelog'
import './pub/components/memberships'
import './pub/components/projects'
import './pub/components/carrier_connections'
import './pub/components/carriers'
import './pub/components/receivables'
import './pub/components/wallets'
import './pub/components/resource_kinds'
import './pub/components/segments'
import './pub/components/sub_segments'
import './pub/components/receivable_kinds'
import './pub/components/resource_sources'
import './pub/components/movement_kinds'
import './pub/components/renegotiations'
import './pub/components/providers'
import './pub/components/renegotiation_installments'
import './pub/components/renegotiation_payments'
import './pub/components/renegotiation_attachments'
import './pub/components/availability_templates'
import './pub/components/availability'
import './pub/components/companies'
import './pub/components/risk_controls'
import './pub/components/risk'
import './pub/components/permissions'
import './pub/components/faq'
import './pub/components/project_availabilities'
import './pub/components/carrier_groups'
import './pub/components/indicators'
import './pub/components/indicator_connections'
import './pub/components/indicator_entries'












import './pub/overrides'

console.groupEnd();
